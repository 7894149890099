import { Link } from 'react-router-dom';
import { Button, mergeClassnames } from '@heathmont/moon-core-tw';
import { GenericInfo } from '@heathmont/moon-icons-tw';
import { btnDownloadCN, btnStatic } from '../../styles/buttons';
import { IBookingOverviewWrapper } from './types';
import {
  actionsWrapperCN,
  subTitleCN,
  warningCN,
  contentCN,
  wrapperCN,
  headerCN,
  titleCN,
  linkCN,
} from './styles';

const BookingOverviewWrapper: React.FC<IBookingOverviewWrapper> = ({
  isTicketsDownloading,
  isInvoiceDownloading,
  hasPayment,
  subTitle,
  notFound,
  title,
  handleDownloadInvoice,
  handleDownloadTicket,
  renderFlights,
  renderWarning,
}) => {
  if (notFound) {
    return (
      <div className={wrapperCN}>
        <div className={headerCN}>
          <span className={titleCN}>
            404 Not found
          </span>
          <span className={subTitleCN}>
            To check all your bookings  follow the <Link className={linkCN} to="/account/bookings">link</Link>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={wrapperCN}>
      <div className={headerCN}>
        <span className={titleCN}>
          {title}
        </span>
        <span className={subTitleCN}>
          {subTitle}
        </span>
      </div>

      <div className={contentCN}>
        {renderFlights && renderFlights()}

        {renderWarning && (
          <span className={warningCN}>
            <GenericInfo className='flex-shrink-0 text-moon-24 text-chichi' />
            <span>{renderWarning()}</span>
          </span>
        )}
      </div>

      <div className={actionsWrapperCN}>
        {
          hasPayment && (
            <Button
              className={mergeClassnames(btnDownloadCN, btnStatic)}
              animation={isInvoiceDownloading ? 'progress' : undefined}
              disabled={isInvoiceDownloading}
              size='xl'
              onClick={handleDownloadInvoice}
            >
              Download invoice
            </Button>
          )
        }
        <Button
          className={mergeClassnames(btnDownloadCN, btnStatic)}
          animation={isTicketsDownloading ? 'progress' : undefined}
          disabled={isTicketsDownloading}
          size='xl'
          onClick={handleDownloadTicket}
        >
          Download ticket
        </Button>
      </div>
    </div>
  );
};

export default BookingOverviewWrapper;
