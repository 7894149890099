import { mergeClassnames } from '@heathmont/moon-core-tw';

export const checkboxLabelCN = mergeClassnames([
  'text-moon-16',
  'text-bulma',
  'max-w-[calc(100%-32px)]',
  'm-0',
]);

export const textEllipsisCN = mergeClassnames([
  'overflow-ellipsis',
  'whitespace-nowrap',
  'overflow-hidden',
]);
