import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { IncludedBagOptionValue } from '../../enums/IncludedBag';
import { InternalPassenger } from '../../enums/InternalPassenger';
import { Passenger } from '../../enums/Passenger';

export const isAllBagOptionsHeterogeneous = (bags: IncludedBagOptionValue[]): boolean => {
  const byOfferId = groupBy(bags, 'offerId');
  return Object.keys(byOfferId).every((offerId) => {
    return uniqBy(byOfferId[offerId], (bag) => {
      let labels: (string | number)[] = [bag.quantity];
      
      if (bag.weight) {
        labels.push(bag.weight);
      }

      if (bag.weightUnit) {
        labels.push(bag.weightUnit);
      }

      return labels.join('_');
    }).length === 1
  });
};

export const isBagOptionsSinglePerFlight = (bags: IncludedBagOptionValue[]): boolean => {
  return uniqBy(bags, 'label').length !== bags.length;
};

export const validateBaggages = (bags: IncludedBagOptionValue[], passengers: Passenger[]) => {
  const validations = passengers.map(passenger => {
    const passengerBags = bags.filter(bag => bag.passengerId === passenger.id);

    if (isBagOptionsSinglePerFlight(passengerBags)) {
      return 'Only 1 additional baggage option can be added to the outbound flight and 1 for return flight';
    }
    
    if (!isAllBagOptionsHeterogeneous(passengerBags)) {
      return 'Bag options can not be heterogeneous';
    }

    return null;
  });

  if (validations.every(validation => !validation)) {
    return null;
  }

  return validations;
};

export const extractPassengerLoyaltyPrograms = (passenger: InternalPassenger): string[] => {
  const loyaltyNumbers = [];

  if (passenger?.loyaltyNumbers?.length) {
    loyaltyNumbers.push(...passenger?.loyaltyNumbers.map(loyaltyNumber => loyaltyNumber.number));
  }

  if (passenger?.loyaltyNumber?.number) {
    loyaltyNumbers.push(passenger.loyaltyNumber.number);
  }

  return loyaltyNumbers;
};

const isLoyaltyNumbersUniq = (loyaltyNumbers: string[]): boolean => {
  return uniq(loyaltyNumbers).length === loyaltyNumbers.length;
};

export const isPassengerLoyaltyNumbersUniq = (passenger: InternalPassenger): boolean => {
  const loyaltyNumbers = extractPassengerLoyaltyPrograms(passenger);
  return isLoyaltyNumbersUniq(loyaltyNumbers);
};

export const isPassengersLoyaltyNumbersUniq = (passengers: InternalPassenger[]): boolean => {
  const loyaltyNumbers = [];

  passengers.forEach(passenger => {
    const passengerLoyaltyNumbers = extractPassengerLoyaltyPrograms(passenger);
    loyaltyNumbers.push(...uniq(passengerLoyaltyNumbers));
  });

  return isLoyaltyNumbersUniq(loyaltyNumbers);
};
