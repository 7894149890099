import { InsetInput, Group, Hint, mergeClassnames } from '@heathmont/moon-core-tw';
import { GenericInfo } from '@heathmont/moon-icons-tw';
import { fieldLabelWrapperCN, fieldWrapperCN, fieldLabelCN, groupCN } from '../../styles/form-field';
import { checkboxLabelCN, textEllipsisCN } from '../../styles/texts';
import SelectOptions from '../SelectOptions';
import CheckboxGroup from '../CheckboxGroup';
import { ILoyaltyProgramsSelector } from './types';
import { clearButtonCN, warningCN } from './styles';
import {
  getUserLoyaltyNumberOptions,
  getLoyaltyNumbersWarning,
  selectLoyaltyNumber,
  getFieldBaseProps,
} from './utils';

const LoyaltyProgramsSelector: React.FC<ILoyaltyProgramsSelector> = ({
  loyaltyNumbersErrors,
  loyaltyNumberErrors,
  userLoyaltyNumbers,
  loyaltyNumbers,
  loyaltyNumber,
  passengerId,
  airlines,
  touched,
  handleResetLoyaltyPrograms,
  setFieldTouched,
  setFieldValue,
}) => {
  const renderLoyaltyNumberLabel = (title: string) => (
    <span
      dangerouslySetInnerHTML={{ __html: title }}
      className={mergeClassnames([ checkboxLabelCN, textEllipsisCN ])}
    />
  );

  const handleSelectLoyaltyNumber = (checkedLoyaltyNumberId: number) => {
    const updatedLoyaltyNumbers = selectLoyaltyNumber(checkedLoyaltyNumberId, loyaltyNumbers, userLoyaltyNumbers);
    setFieldValue('loyaltyNumbers', updatedLoyaltyNumbers);
  };

  return (
    <div className={fieldWrapperCN}>
      <div className={fieldLabelWrapperCN}>
        <h5 className={fieldLabelCN}>
          Frequent flyer (optional)
        </h5>

        <span className={clearButtonCN} onClick={handleResetLoyaltyPrograms}>
          Clear all
        </span>
      </div>

      {userLoyaltyNumbers?.length > 0 && (
        <div className='mb-[10px]'>
          <CheckboxGroup
            selectedValues={loyaltyNumbers.map(loyaltyNumber => loyaltyNumber.id)}
            data={getUserLoyaltyNumberOptions(userLoyaltyNumbers, airlines)}
            renderCheckboxLabel={renderLoyaltyNumberLabel}
            onSelect={handleSelectLoyaltyNumber}
          />
        </div>
      )}

      <Hint className={warningCN}>
        <GenericInfo className='flex-shrink-0' />
        {getLoyaltyNumbersWarning(userLoyaltyNumbers.length)}
      </Hint>

      <Group className={groupCN}>
        <Group.FirstInsetSelect
          {...getFieldBaseProps('loyaltyNumber.airlineId', passengerId)}
          error={!!(loyaltyNumberErrors?.airlineId && touched?.airlineId)}
          value={loyaltyNumber.airlineId || ''}
          label={'Frequent flyer program'}
          onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.airlineId', value)}
          onBlur={() => setFieldTouched('loyaltyNumber.airlineId')}
        >
          <SelectOptions options={airlines} label='frequent flyer program' />
        </Group.FirstInsetSelect>
      
        <Group.LastInsetInput
          {...getFieldBaseProps('loyaltyNumber.number', passengerId)}
          placeholder=' '
          error={!!(loyaltyNumberErrors?.number && touched?.number)}
          value={loyaltyNumber.number}
          type='text'
          onChange={({ target: { value } }) => setFieldValue('loyaltyNumber.number', value)}
          onBlur={() => setFieldTouched('loyaltyNumber.number')}
        >
          <InsetInput.Label>Flyer number</InsetInput.Label>
        </Group.LastInsetInput>
      </Group>
      
      {!!(loyaltyNumberErrors?.airlineId && touched?.airlineId) && (
        <Hint error>{loyaltyNumberErrors?.airlineId}</Hint>
      )}

      {!!(loyaltyNumberErrors?.number && touched?.number) && (
        <Hint error>{loyaltyNumberErrors?.number}</Hint>
      )}

      {!!loyaltyNumbersErrors && typeof loyaltyNumbersErrors === 'string' && (
        <Hint error>{loyaltyNumbersErrors}</Hint>
      )}
    </div>
  );
};

export default LoyaltyProgramsSelector;
