import { useEffect, useMemo, useState } from 'react';
import AdditionalBaggageSelector from '../AdditionalBaggageSelector';
import LoyaltyProgramsSelector from '../LoyaltyProgramsSelector';
import UsersSearchSelect from '../UsersSearchSelect';
import { IFlightBookingPassengerForm } from './types';
import { handleFetchWarnings } from './utils';

const InternalPassengerForm: React.FC<IFlightBookingPassengerForm> = ({
  additionalBagError,
  additionalBags,
  passenger,
  airlines,
  touched,
  errors,
  users,
  bags,
  handleSelectBaggage,
  setFieldTouched,
  setFieldValue,
  setValues,
}) => {
  const [warning, setWarning] = useState(null);

  const userLoyaltyNumbers = useMemo(() => {
    if (passenger.userId) {
      const userData = users.find(user => +user.id === +passenger.userId);
      return userData?.loyaltyNumbers?.length ? userData.loyaltyNumbers : [];
    }

    return [];
  }, [users, passenger.userId]);
 
  const handleChangePassenger = (userId) => {
    const userData = users.find(user => +user.id === +userId);

    setValues({
      ...passenger,
      loyaltyNumbers: [],
      firstName: userData.firstName,
      lastName: userData.lastName,
      userId,
      email: userData.email,
    });
  };

  const handleResetLoyaltyPrograms = () => {
    setValues({
      ...passenger,
      loyaltyNumbers: [],
      loyaltyNumber: {
        airlineId: null,
        number: '',
      },
    })
  };

  useEffect(() => {
    if (passenger?.userId) {
      handleFetchWarnings(passenger?.userId, setWarning);
    }
  }, [passenger?.userId]);

  return (
    <div className='w-full flex flex-col gap-[16px]'>
      <UsersSearchSelect
        placeholder='Select passenger'
        isWarning={!!(warning && touched?.userId)}
        warning={warning}
        isError={!!(errors?.userId && touched?.userId)}
        error={errors?.userId}
        label={'Passenger'}
        users={users}
        value={passenger?.userId}
        setTouched={() => setFieldTouched('userId')}
        onChange={(value) => handleChangePassenger(value)}
      />

      <AdditionalBaggageSelector
        selectedOptions={additionalBags}
        options={bags}
        error={additionalBagError}
        onSelect={handleSelectBaggage}
      />

      <LoyaltyProgramsSelector
        loyaltyNumbersErrors={errors?.loyaltyNumbers}
        loyaltyNumberErrors={errors?.loyaltyNumber}
        userLoyaltyNumbers={userLoyaltyNumbers}
        loyaltyNumbers={passenger.loyaltyNumbers}
        loyaltyNumber={passenger.loyaltyNumber}
        passengerId={passenger.id}
        airlines={airlines}
        touched={touched?.loyaltyNumber}
        handleResetLoyaltyPrograms={handleResetLoyaltyPrograms}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default InternalPassengerForm;
