import { mergeClassnames } from '@heathmont/moon-core-tw';

export const wrapperCN = mergeClassnames([
  'px-[0]',
  'py-[60px]',
  'w-[892px]',
  'max-w-full',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'mx-[auto]',
  'my-[0]',
  '-mt-[113px]',
  'max-tablet:w-full',
  'max-tablet:-mt-[94px]',
  'max-tablet:pt-[100px]',
  'max-tablet:px-[0]',
  'max-tablet:pb-[120px]',
]); 

export const headerCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'pt-[0]',
  'px-[80px]',
  'pb-[20px]',
  'max-tablet:px-[0]',
  'max-tablet:pb-[0]',
]);

export const titleCN = mergeClassnames([
  'text-moon-40',
  'max-laptop:text-moon-32',
  'max-tablet:text-moon-24',
  'text-bulma',
  'font-medium',
  'text-center',
  'mb-[12px]',
]);

export const subTitleCN = mergeClassnames([
  'text-moon-16',
  'text-bulma',
  'text-center',
  'mt-[10px]',
]);

export const warningCN = mergeClassnames([
  'flex',
  'items-center',
  'gap-2',
  'text-moon-16',
  'text-bulma',
  'mt-[10px]',
]);

export const linkCN = mergeClassnames([
  'text-bulma',
  'font-normal',
  'underline',
  'hover:text-bulma',
  'hover:underline',
  'hover:[text-shadow:0_0_0.01px_popo]',
]);

export const contentCN = mergeClassnames([
  'w-full',
  'flex',
  'flex-col',
  'gap-[20px]',
  'my-[40px]',
]);

export const actionsWrapperCN = mergeClassnames([
  'w-full',
  'flex',
  'items-center',
  'justify-center',
  'gap-[10px]',
  'fixed',
  'bottom-[20px]',
  'ml-[var(--scrollbar-width)]',
  'max-tablet:flex-col',
  'z-10',
]);
