import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IFlightBookingConfirmation } from './types';
import PageWrapper from '../../components/PageWrapper';
import FlightBookingOffer from '../../components/FlightBookingOffer';
import {
  extractNotAppliedLoyaltyPrograms,
  handleFetchBookingDetails,
  defineTitleAndSubTitle,
  handleDownloadInvoice,
  handleDownloadTicket,
  getFlightReference,
  isInvoicePayment,
  isSuccessPayment,
} from './utils';
import { extractAdditionalServices, extractFareDetails } from '../../utils/fare-rules';
import useIsMobile from '../../hooks/useIsMobile';
import { Passenger } from '../../enums/Passenger';
import { Payment } from '../../enums/Payment';
import FlightBookingFeedbackDialog from '../../components/FlightBookingFeedbackDialog';
import BookingOverviewWrapper from '../../components/BookingOverviewWrapper';
import { AssociatedRecord } from '../../enums/AssociatedRecord';
import { TripPurpose } from '../../enums/TripPurpose';
import { Trip } from '../../enums/Trip';

const FlightBookingConfirmation: React.FC<IFlightBookingConfirmation> = ({
  isFeedbackModalOpen,
  currentUser,
  setIsFeedbackFormOpen,
  resetToInitial,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [booking, setBooking] = useState<{
    associatedRecords: AssociatedRecord[];
    tripPurpose: TripPurpose,
    travelers: Passenger[];
    payment: Payment;
    offers: any[];
    trips: Trip[];
    price: number;
  }>({
    associatedRecords: [],
    tripPurpose: null,
    travelers: [],
    payment: null,
    offers: [],
    trips: [],
    price: 0,
  });
  const [isBookingLoading, setIsBookingLoading] = useState<boolean>(true);
  const [isTicketsDownloading, setIsTicketsDownloading] = useState<boolean>(false);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState<boolean>(false);
  const { amaClientRef } = useParams();
  const { title, subTitle } = useMemo(
    () => defineTitleAndSubTitle({
      paymentRequired: booking?.tripPurpose?.paymentRequired,
      paymentType: booking?.payment?.paymentType,
      status: booking?.payment?.status,
      price: booking?.price,
    }),
    [
      booking?.tripPurpose?.paymentRequired,
      booking?.payment?.paymentType,
      booking?.payment?.status,
      booking?.price,
    ],
  );
  const tripId = useMemo(() => {
    const trip = booking.trips.find(trip => trip.user.id === currentUser.id);
    return trip ? trip.id : null;
  }, [booking.trips, currentUser.id]);

  const notAppliedLoyaltyPrograms = useMemo(() => {
    return extractNotAppliedLoyaltyPrograms(booking.trips);
  }, [booking.trips, currentUser.id]);

  const isBookingHasWarnings = useMemo(() => {
    return notAppliedLoyaltyPrograms?.length > 0;
  }, [notAppliedLoyaltyPrograms]);

  const backToFlightsSearch = () => {
    navigate('/');
  };

  useEffect(() => {
    if (location?.state?.resetToInitial) {
      resetToInitial();
    }
  }, [location?.state?.resetToInitial]);

  useEffect(() => {
    handleFetchBookingDetails({
      amaClientRef,
      setIsFeedbackFormOpen,
      setIsBookingLoading,
      handleError: backToFlightsSearch,
      setBooking,
    });

    return () => {
      resetToInitial();
    };
  }, []);

  const renderFlights = useCallback(() => {
    return booking.offers.map((offer) => {
      const offerAssociatedRecords = booking.associatedRecords.filter(associatedRecord => (
        associatedRecord.flightOfferId === offer.id
      ));
      return (
        <FlightBookingOffer
          withTravelersInfo
          withReference
          travelers={booking.travelers}
          isLoading={isBookingLoading}
          reference={getFlightReference(offerAssociatedRecords)}
          offer={offer}
          key={offer.id}
          getAdditionalServices={(travelerId, segmentId) => extractAdditionalServices({
            travelerId,
            segmentId,
            offer,
          })}
          getFareDetails={(travelerId, segmentId) => extractFareDetails({
            travelerId,
            segmentId,
            offer,
          })}
        />
      );
    });
  }, [booking]);

  const renderWarnings = () => {
    const notAppliedLoyaltyProgramsItems = notAppliedLoyaltyPrograms.map((notAppliedLoyaltyProgram, index) => {
      return (
        <>
          <b>{notAppliedLoyaltyProgram}</b>{index !== notAppliedLoyaltyPrograms.length - 1 ? ', ' : ''}
        </>
      )
    });

    return (
      <>
        At least one of the frequent flyer programs has not been applied. Flyer program affected: {notAppliedLoyaltyProgramsItems}.
      </>
    );
  };

  return (
    <PageWrapper
      withBackgroundImage
      backgroundColor
      lightAuth
      isMobile={isMobile}
      page={'flight-booking-confirmation'}
    >
      {!isBookingLoading && (
        <BookingOverviewWrapper
          isTicketsDownloading={isTicketsDownloading}
          isInvoiceDownloading={isInvoiceDownloading}
          hasPayment={isInvoicePayment(booking?.payment?.paymentType) || isSuccessPayment(booking?.payment?.status)}
          subTitle={subTitle}
          notFound={!booking?.trips?.length}
          title={title}
          handleDownloadInvoice={() => handleDownloadInvoice({
            amaClientRef,
            paymentId: booking?.payment?.id,
            setIsInvoiceDownloading,
          })}
          handleDownloadTicket={() => handleDownloadTicket({
            amaClientRef,
            trips: booking.trips,
            setIsTicketsDownloading,
          })}
          renderFlights={renderFlights}
          renderWarning={isBookingHasWarnings ? renderWarnings : null}
        />
      )}

      {isFeedbackModalOpen && tripId && (
        <FlightBookingFeedbackDialog
          tripId={tripId}
          userId={currentUser.id}
          onClose={() => setIsFeedbackFormOpen(false, amaClientRef)}
        />
      )}
    </PageWrapper>
  );
};

export default FlightBookingConfirmation;
