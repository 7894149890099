import { LoyaltyNumber } from '../../enums/LoyaltyNumber';
import { AirlineOption } from '../../enums/AirlineOption';

export const getFieldBaseProps = (field: string, passengerId: number) => {
  return {
    name: `${field}_${passengerId}`, 
    id: `${field}_${passengerId}`,
  };
};

const formatLoyaltyNumberLabel = (loyaltyNumber: LoyaltyNumber, airline: AirlineOption) => {
  return `${airline.label} - <b>${loyaltyNumber.number}</b>`
};

export const getUserLoyaltyNumberOptions = (userLoyaltyNumbers: LoyaltyNumber[], airlines: AirlineOption[]) => {
  return userLoyaltyNumbers.map(loyaltyNumber => {
    const airline = airlines.find((airline) => loyaltyNumber.airlineId === airline.value);
    return {
      title: formatLoyaltyNumberLabel(loyaltyNumber, airline),
      value: loyaltyNumber.id,
    };
  });
};

export const selectLoyaltyNumber = (
  checkedLoyaltyNumberId: number,
  loyaltyNumbers: LoyaltyNumber[],
  userLoyaltyNumbers: LoyaltyNumber[],
): LoyaltyNumber[] => {
  const isAlreadySelected = loyaltyNumbers.some(loyaltyNumber => loyaltyNumber.id === checkedLoyaltyNumberId);

  if (isAlreadySelected) {
    return loyaltyNumbers.filter(loyaltyNumber => loyaltyNumber.id !== checkedLoyaltyNumberId);
  }

  const selectedLoyaltyNumber = userLoyaltyNumbers.find(loyaltyNumber => loyaltyNumber.id === checkedLoyaltyNumberId)

  return [...loyaltyNumbers, selectedLoyaltyNumber];
};

export const getLoyaltyNumbersWarning = (userLoyaltyNumbersLength: number): string => {
  const MAX_LOYALTY_NUMBERS_PER_USER = 10;
  let warning = 'You can provide an additional frequent flyer program.'
  
  if (userLoyaltyNumbersLength >= MAX_LOYALTY_NUMBERS_PER_USER) {
    warning += ` User can have up to ${MAX_LOYALTY_NUMBERS_PER_USER} programs. New program will not be saved for future flights but will be applied for current booking.`;
  } else {
    warning += ' This program will be saved and can be used for future flights.';
  }

  return warning;
};
