import moment from 'moment';

import { InternalPassenger } from '../../enums/InternalPassenger';
import { CurrentUser } from '../../enums/CurrentUser';
import { Passenger } from '../../enums/Passenger';
import { getFlightEndDate } from '../../utils/flights';
import request from '../../utils/request';
import Yup from '../../utils/validation';
import {
  isPassengersLoyaltyNumbersUniq,
  isPassengerLoyaltyNumbersUniq,
} from './utils';

import { MAX_NUMBER_PASSENGERS } from '../../constants';

const VALIDATIONS = {
  NAME: {
    TOTAL: 30,
    MAX: 20,
    MIN: 2,
  },
  PASSENGER_AGE: {
    MAX: 100,
    MIN: 18,
  },
  DOCUMENT_EXPIRATION_DATE: {
    MAX: 50,
    MIN: 3,
  },
  DOCUMENT_NUMBER: {
    MAX: 15,
    MIN: 5,
  },
  FLYER_NUMBER: {
    MAX: 23,
    MIN: 1,
  },
};

const validateMessages = {
  passengers: {
    min: 'Min number of passengers is 1',
    max: `Max number of passengers is ${MAX_NUMBER_PASSENGERS}`,
    uniq: (field) => field
      ? `Passenger\'s ${field}s must be unique`
      : 'Passengers must be unique',
    withPrimaryContact: 'Please select one of the passengers as the Primary contact. We will reach him out if any changes are needed',
  },

  id: {
    required: 'ID cannot be empty',
  },

  userId: {
    required: 'Passenger cannot be empty',
  },

  firstName: {
    validName: 'First name can contain only Latin characters and hyphens',
    required: 'First name cannot be empty',
    min: `First name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `First name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  lastName: {
    validName: 'Last name can contain only Latin characters and hyphens',
    combined: `Full name cannot be longer than ${VALIDATIONS.NAME.TOTAL} characters`,
    required: 'Last name cannot be empty',
    min: `Last name cannot be less than ${VALIDATIONS.NAME.MIN} characters`,
    max: `Last name cannot be longer than ${VALIDATIONS.NAME.MAX} characters`,
  },
  email: {
    disallowedSymbol: 'Email cannot contain "+"',
    required: 'Email cannot be empty',
    valid: 'Email should be in format "user@example.com"',
  },
  birthday: {
    required: 'Birth date cannot be empty',
    max: `Age cannot be more then ${VALIDATIONS.PASSENGER_AGE.MAX} years old`,
    min: `Age cannot be less then ${VALIDATIONS.PASSENGER_AGE.MIN} years old`,
  },
  phoneCountyCode: {
    required: 'Country cannot be empty',
  },
  phoneNumber: {
    required: 'Telephone number cannot be empty',
  },
  gender: {
    required: 'Gender cannot be empty',
  },

  document: {
    country: {
      required: 'Country issued document cannot be empty',
    },
    type: {
      required: 'Document type cannot be empty',
    },
    number: {
      alphanumeric: 'Document number can contain only alpha-numeric chars (without spaces)',
      required: 'Document number cannot be empty',
      min: `Document number cannot be less than ${VALIDATIONS.DOCUMENT_NUMBER.MIN} characters`,
      max: `Document number cannot be longer than ${VALIDATIONS.DOCUMENT_NUMBER.MAX} characters`,
    },
    validTill: {
      required: 'Document expiration date cannot be empty',
      max: `Document expiration date cannot be after ${VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MAX} years from today`,
      min: `Document expiration date can not be sooner than ${VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MIN} months after trip end date`,
    },
  },

  loyaltyNumber: {
    airlineId: {
      required: 'Frequent flyer program cannot be empty if a flyer number is provided',
    },
    number: {
      alphanumericSlashAndHyphen: 'Flyer number can contain only alpha-numeric chars, slashes, hyphens and spaces',
      required: 'Flyer number cannot be empty if a frequent flyer program is provided',
      min: `Flyer number cannot be less than ${VALIDATIONS.FLYER_NUMBER.MIN} characters`,
      max: `Flyer number cannot be longer than ${VALIDATIONS.FLYER_NUMBER.MAX} characters`,
    },
  },
};

const internalUserPassengerDataValidation = async ({
  tripEndDate,
  userId,
  createError,
} : {
  tripEndDate: string,
  userId: number,
  createError: (params: Yup.CreateErrorOptions) => Yup.ValidationError,
}) => {
  try {
    const response = await request.get(`white-label/user/validate/${userId}`, {
      params: {
        tripEndDate,
      },
    });

    if (response?.data?.errors?.length) {
      const error = response?.data?.errors?.join('. ');
      return createError({
        message: `Please check with your fellow passenger as their passenger data is invalid. ${error}`
      });
    }

    return true;
  } catch (err) {
    return true;
  }
};

const initLoyaltyNumberSchema = () => {
  return Yup
    .object()
    .shape({
      airlineId: Yup
        .number()
        .nullable()
        .requiredIfProvided('number', validateMessages.loyaltyNumber.airlineId.required),
      number: Yup.string()
        .min(VALIDATIONS.FLYER_NUMBER.MIN, validateMessages.loyaltyNumber.number.min)
        .max(VALIDATIONS.FLYER_NUMBER.MAX, validateMessages.loyaltyNumber.number.max)
        .test('only-alphanumeric-slash-hyphen', validateMessages.loyaltyNumber.number.alphanumericSlashAndHyphen, (value = '') => (
          /^[a-z0-9-/ ]*$/i.test(value)
        ))
        .nullable()
        .requiredIfProvided('airlineId', validateMessages.loyaltyNumber.number.required)
        .withoutSpaces(),
    })
    .nullable();
};

export const internalUserPassengerSchemaInit = (tripEndDate) => {
  return Yup
    .object()
    .shape({
      id: Yup
        .number()
        .min(1)
        .required(validateMessages.id.required),
      firstName: Yup
        .string()
        .required(validateMessages.firstName.required)
        .min(VALIDATIONS.NAME.MIN, validateMessages.firstName.min)
        .max(VALIDATIONS.NAME.MAX, validateMessages.firstName.max)
        .withoutSpaces()
        .validName(validateMessages.firstName.validName)
        .isInLatinCharacters(),
      lastName: Yup
        .string()
        .required(validateMessages.lastName.required)
        .min(VALIDATIONS.NAME.MIN, validateMessages.lastName.min)
        .max(VALIDATIONS.NAME.MAX, validateMessages.lastName.max)
        .withoutSpaces()
        .validName(validateMessages.lastName.validName)
        .isInLatinCharacters()
        .test('max-length-full-name', validateMessages.lastName.combined, (value, context) => {
          if (!value || !context.parent.firstName) {
            return true;
          }

          return `${value} ${context.parent.firstName}`.length <= VALIDATIONS.NAME.TOTAL;
        }),
      email: Yup
        .string()
        .email(validateMessages.email.valid)
        .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
        .required(validateMessages.email.required),
      userId: Yup
        .number()
        .min(1)
        .required(validateMessages.userId.required)
        .test('passenger-data-validation', async (userId, { createError }) => {
          const validationError = await internalUserPassengerDataValidation({
            tripEndDate,
            userId,
            createError,
          });
          return validationError;
        }),
      loyaltyNumbers: Yup
        .array()
        .of(initLoyaltyNumberSchema())
        .test(
          'is-passenger-flyer-numbers-uniq',
          validateMessages.passengers.uniq('flyer number'),
          (value, ctx) => isPassengerLoyaltyNumbersUniq(ctx.parent),
        ),
      loyaltyNumber: initLoyaltyNumberSchema(),
    });
};

export const passengerSchemaInit = (tripEndDate) => {
  return Yup
    .object()
    .shape({
      id: Yup
        .number()
        .min(1)
        .required(validateMessages.id.required),
      firstName: Yup
        .string()
        .required(validateMessages.firstName.required)
        .min(VALIDATIONS.NAME.MIN, validateMessages.firstName.min)
        .max(VALIDATIONS.NAME.MAX, validateMessages.firstName.max)
        .withoutSpaces()
        .validName(validateMessages.firstName.validName)
        .isInLatinCharacters(),
      lastName: Yup
        .string()
        .required(validateMessages.lastName.required)
        .min(VALIDATIONS.NAME.MIN, validateMessages.lastName.min)
        .max(VALIDATIONS.NAME.MAX, validateMessages.lastName.max)
        .withoutSpaces()
        .validName(validateMessages.lastName.validName)
        .isInLatinCharacters()
        .test('max-length-full-name', validateMessages.lastName.combined, (value, context) => {
          if (!value || !context.parent.firstName) {
            return true;
          }

          return `${value} ${context.parent.firstName}`.length <= VALIDATIONS.NAME.TOTAL;
        }),
      email: Yup
        .string()
        .email(validateMessages.email.valid)
        .disallowedSymbols(['+'], validateMessages.email.disallowedSymbol)
        .required(validateMessages.email.required),
      birthday: Yup
        .date()
        .required(validateMessages.birthday.required)
        .test('max-age', validateMessages.birthday.max, (value) => (
          moment().diff(moment(value), 'years') <= VALIDATIONS.PASSENGER_AGE.MAX
        ))
        .test('min-age', validateMessages.birthday.min, (value) => (
          moment().diff(moment(value), 'years') >= VALIDATIONS.PASSENGER_AGE.MIN
        ))
        .nullable(),
      phoneCountryCode: Yup
        .string()
        .required(validateMessages.phoneCountyCode.required),
      phoneNumber: Yup
        .number()
        .required(validateMessages.phoneNumber.required),
      phone: Yup
        .string()
        .isValidPhoneNumber(),
      gender: Yup
        .string()
        .required(validateMessages.gender.required),
      document: Yup
        .object()
        .when('documentRequired', ([documentRequired]) => {
          if (documentRequired) {
            return Yup
              .object()
              .shape({
                typeId: Yup
                  .number()
                  .required(validateMessages.document.type.required),
                countryId: Yup
                  .number()
                  .required(validateMessages.document.country.required),
                number: Yup
                  .string()
                  .min(VALIDATIONS.DOCUMENT_NUMBER.MIN, validateMessages.document.number.min)
                  .max(VALIDATIONS.DOCUMENT_NUMBER.MAX, validateMessages.document.number.max)
                  .withoutSpaces()
                  .test('only-alphanumeric', validateMessages.document.number.alphanumeric, (value = '') => (
                    /^[a-z0-9]*$/i.test(value)
                  ))
                  .required(validateMessages.document.number.required),
                validTill: Yup
                  .date()
                  .required(validateMessages.document.validTill.required)
                  .test('max-expiration-date', validateMessages.document.validTill.max, (value) => (
                    moment().add(VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MAX, 'years').isAfter(value)
                  ))
                  .test('min-expiration-date', validateMessages.document.validTill.min, (value) => (
                    moment(tripEndDate).add(VALIDATIONS.DOCUMENT_EXPIRATION_DATE.MIN, 'months').isBefore(value)
                  ))
                  .nullable(),
              });
          } else {
            return Yup
              .object()
              .nullable();
          }
        }),
      loyaltyNumber: initLoyaltyNumberSchema(),
    });
};

export const passengersSchema = ({
  isInternalBusinessTrip,
  selectedOffers,
}: {
  isInternalBusinessTrip: boolean;
  selectedOffers: any[];
}) => {
  const tripEndDate = getFlightEndDate(selectedOffers);

  if (isInternalBusinessTrip) {
    const uniqFields = [
      { name: 'userId', label: null },
    ];
    return Yup
      .object()
      .shape({
        passengers: Yup
          .array()
          .of(internalUserPassengerSchemaInit(tripEndDate))
          .min(1, validateMessages.passengers.min)
          .max(MAX_NUMBER_PASSENGERS, validateMessages.passengers.max)
          .uniqBy(uniqFields, validateMessages.passengers.uniq)
          .test(
            'is-passengers-flyer-numbers-uniq',
            validateMessages.passengers.uniq('flyer number'),
            isPassengersLoyaltyNumbersUniq,
          )
      });
  }

  const uniqFields = [
    { name: 'email', label: 'email' },
    { name: 'loyaltyNumber.number', label: 'flyer number' },
  ];

  return Yup
    .object()
    .shape({
      passengers: Yup
        .array()
        .of(passengerSchemaInit(tripEndDate))
        .min(1, validateMessages.passengers.min)
        .max(MAX_NUMBER_PASSENGERS, validateMessages.passengers.max)
        .uniqBy(uniqFields, validateMessages.passengers.uniq),    
    });
};

export const getInitialValues = ({
  isInternalBusinessTrip,
  currentUser,
  passengers
}: {
  isInternalBusinessTrip: boolean;
  currentUser: CurrentUser
  passengers: Passenger[],
}): Passenger[] | InternalPassenger[] => {
  if (isInternalBusinessTrip) {
    return passengers.map((passenger, index) => {
      return {
        loyaltyNumbers: [],
        loyaltyNumber: {
          airlineId: undefined,
          number: '',
        },
        firstName: index === 0 ? currentUser.firstName : '',
        lastName: index === 0 ? currentUser.lastName : '',
        userId: index === 0 ? currentUser.id : null,
        email: index === 0 ? currentUser.email : null,
        id: passenger.id,
      };
    });
  }

  return passengers;
};

export const getInitialTouched = ({
  isInternalBusinessTrip,
  passengers,
}) => {
  if (isInternalBusinessTrip) {
    return passengers.map(({}, index) => {
      if (index === 0) {
        return { userId: true };
      }

      return null;
    });
  }

  return {};
};
