import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { InsetInput, Group, Hint, InsetNativeSelect } from '@heathmont/moon-core-tw';
import SelectOptions from '../SelectOptions';
import AuthForm from '../AuthForm';
import InsetPassword from '../InsetPassword';
import { genderOptions } from '../../enums/GenderType';
import { routerLink } from '../../styles/buttons';
import request from '../../utils/request';
import { countyCodes, formatPhone, omitHelperPhoneFields } from '../../utils/phone';
import { getInitialValues, userSchema } from './schema';
import { useQuery } from '../../hooks/useQuery';
import { extractErrorFromResponse } from '../../utils/errors';

const SignUpForm = () => {
  const { getQuery } = useQuery();
  const { referralCode } = useMemo(() => getQuery(), []) as { referralCode: string };
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
  const [isReferralCodeDisabled, setIsReferralCodeDisabled] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: userSchema,
    validateOnBlur: true,
    initialValues: getInitialValues({}),
    onSubmit: () => {},
  });
  const { values, errors, touched, setFieldValue, setFieldTouched, validateForm, setValues } = formik;

  useEffect(() => {
    if (touched?.phoneCountryCode && touched?.phoneNumber) {
      setFieldTouched('phone');
    }
  }, [touched?.phoneCountryCode, touched?.phoneNumber]);

  useEffect(() => {
    setFieldValue('phone', formatPhone(values?.phoneCountryCode, values?.phoneNumber));
  }, [values?.phoneCountryCode, values?.phoneNumber]);

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    if (referralCode) {
      handleCheckReferralCode();
    }
  }, [referralCode]);

  const handleCheckReferralCode = async () => {
    try {
      const response = await request.get(`white-label/invitations/${referralCode}`);
    
      if (response?.data?.invitation) {
        setValues(getInitialValues(response?.data?.invitation));
        setIsReferralCodeDisabled(true);
        response?.data?.invitation?.email && setIsEmailDisabled(true);
      } else {
        setErrorMessage('Invitation link is not valid');
      }
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      setIsSubmitLoading(true);
      await request.post('white-label/auth/sign-up', omitHelperPhoneFields(values));
      setSuccessMessage('Please, check your inbox to activate your account. After account activation you can sign in');
    } catch (err) {
      setErrorMessage(extractErrorFromResponse(err));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleTryAgain = () => {
    setErrorMessage(null);
  };

  return (
    <AuthForm
      submitButtonTitle='Sign up'
      isLoading={isSubmitLoading}
      success={successMessage}
      error={errorMessage}
      title='Sign up'
      value={formik}
      handleTryAgain={handleTryAgain}
      renderSubTitle={() => (
        <>
          Already have an account? <Link className={routerLink} to='/sign-in'>Sign in</Link>
        </>
      )}
      renderFields={() => (
        <>
          <div className='w-full flex flex-col'>
            <Group className='rounded-moon-i-md'>
              <Group.FirstInsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                placeholder=' '
                error={!!(errors?.firstName && touched?.firstName)}
                value={values.firstName}
                name='firstName'
                id='firstName'
                onChange={({ target: { value } }) => setFieldValue('firstName', value)}
                onBlur={() => setFieldTouched('firstName')}
              >
                <InsetInput.Label>First name as in your passport</InsetInput.Label>
              </Group.FirstInsetInput>
              <Group.LastInsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                placeholder=' '
                error={!!(errors?.lastName && touched?.lastName)}
                value={values.lastName}
                name='lastName'
                id='lastName'
                onChange={({ target: { value } }) => setFieldValue('lastName', value)}
                onBlur={() => setFieldTouched('lastName')}
              >
                <InsetInput.Label>Last name as in your passport</InsetInput.Label>
              </Group.LastInsetInput>
            </Group>

            {!!(errors?.firstName && touched?.firstName) && (
              <Hint error>{errors?.firstName}</Hint>
            )}
            {!!(errors?.lastName && touched?.lastName) && (
              <Hint error>{errors?.lastName}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <InsetNativeSelect
              className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
              error={!!(errors?.gender && touched?.gender)}
              value={values?.gender || ''}
              label={'Gender'}
              name='gender'
              id='gender'
              onChange={({ target: { value } }) => setFieldValue('gender', value)}
              onBlur={() => setFieldTouched('gender')}
            >
              <SelectOptions options={genderOptions} label='gender' />
            </InsetNativeSelect>

            {!!(errors?.gender && touched?.gender) && (
              <Hint error>{errors?.gender}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <Group className='rounded-moon-i-md'>
              <Group.FirstInsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                placeholder='Birth date'
                error={!!(errors?.birthday && touched?.birthday)}
                value={values.birthday}
                type='date'
                name='birthday'
                id='birthday'
                onChange={({ target: { value } }) => setFieldValue('birthday', value || '')}
                onBlur={() => setFieldTouched('birthday')}
              >
                <InsetInput.Label>Birth date</InsetInput.Label>
              </Group.FirstInsetInput>
              <Group.LastInsetInput
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                placeholder=' '
                disabled={isEmailDisabled}
                error={!!(errors?.email && touched?.email)}
                value={values.email}
                type='email'
                name='email'
                id='email'
                onChange={({ target: { value } }) => setFieldValue('email', value)}
                onBlur={() => setFieldTouched('email')}
              >
                <InsetInput.Label>Email</InsetInput.Label>
              </Group.LastInsetInput>
            </Group>
        
            {!!(errors?.birthday && touched?.birthday) && (
              <Hint error>{errors?.birthday}</Hint>
            )}
            {!!(errors?.email && touched?.email) && (
              <Hint error>{errors?.email}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <Group className='rounded-moon-i-md'>
              <Group.FirstInsetSelect
                className='rounded-moon-i-md [&_select]:rounded-moon-i-md'
                error={!!(errors?.phoneCountryCode && touched?.phoneCountryCode) || !!(errors?.phone && touched?.phone)}
                value={values.phoneCountryCode || ''}
                label={'Country'}
                name='phoneCountryCode'
                id='phoneCountryCode'
                onChange={({ target: { value } }) => setFieldValue('phoneCountryCode', value)}
                onBlur={() => setFieldTouched('phoneCountryCode')}
              >
                <SelectOptions options={countyCodes} label='country' />
              </Group.FirstInsetSelect>
              <Group.LastInsetInput
                placeholder=' '
                className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
                error={!!(errors?.phoneNumber && touched?.phoneNumber) || !!(errors?.phone && touched?.phone)}
                value={values.phoneNumber}
                type='number'
                name='phoneNumber'
                id='phoneNumber'
                onChange={({ target: { value } }) => setFieldValue('phoneNumber', value)}
                onBlur={() => setFieldTouched('phoneNumber')}
              >
                <InsetInput.Label>Telephone number</InsetInput.Label>
              </Group.LastInsetInput>
            </Group>
        
            {!!(errors?.phoneCountryCode && touched?.phoneCountryCode) && (
              <Hint error>{errors?.phoneCountryCode}</Hint>
            )}
            {!!(errors?.phoneNumber && touched?.phoneNumber) && (
              <Hint error>{errors?.phoneNumber}</Hint>
            )}
            {!!(touched?.phone && touched?.phone && !errors?.phoneCountryCode && !errors?.phoneNumber && errors?.phone) && (
              <Hint error>{errors?.phone}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <InsetInput
              placeholder=' '
              className='rounded-moon-i-md [&_input]:rounded-moon-i-md'
              disabled={isReferralCodeDisabled}
              error={!!(errors?.referralCode && touched?.referralCode)}
              value={values.referralCode}
              name='referralCode' 
              type='text'
              id='referralCode'
              onChange={({ target: { value } }) => setFieldValue('referralCode', value)}
              onBlur={() => setFieldTouched('referralCode')}
            >
              <InsetInput.Label>Referral code</InsetInput.Label>
            </InsetInput>

            {(!!errors?.referralCode && touched?.referralCode) && (
              <Hint error>{errors?.referralCode}</Hint>
            )}
          </div>

          <div className='w-full flex flex-col'>
            <Group className='rounded-moon-i-md'>
              <InsetPassword
                component={Group.FirstInsetInput}
                label={'Password'}
                error={!!(errors?.password && touched?.password)}
                value={values.password}
                name={'password'}
                onChange={({ target: { value } }) => setFieldValue('password', value)}
                onBlur={() => setFieldTouched('password')}
              />
              <InsetPassword
                component={Group.LastInsetInput}
                label={'Confirm password'}
                error={!!(!errors?.password && errors?.confirmPassword && touched?.confirmPassword)}
                value={values.confirmPassword}
                name={'confirmPassword'}
                onChange={({ target: { value } }) => setFieldValue('confirmPassword', value)}
                onBlur={() => setFieldTouched('confirmPassword')}
              />
            </Group>

            {!!(errors?.password && touched?.password) && (
              <Hint error>{errors?.password}</Hint>
            )}
            {!!(!errors?.password && errors?.confirmPassword && touched?.confirmPassword) && (
              <Hint error>{errors?.confirmPassword}</Hint>
            )}
          </div>
        </>
      )}
      onSubmit={onSubmit}
    />
  );
};

export default SignUpForm;

